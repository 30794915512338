.drawer {
  max-width: 500px;
  left: 50% !important;
  transform: translate(-50%, 0%) !important;
}

.drawer__handle-wrapper, .drawer__handle {
  display: none;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}

.box-cup-info {
  padding: 16px;
}

.box-cup-info .logo {
  max-width: 200px;
  width: 30%;
  padding-bottom: 6px;
}

.box-cup-info .name {
  font-size: large;
  font-weight: 800;
  color: #2E2E2E;
  padding-bottom: 4px;
}

.box-cup-info .location {
  font-size: medium;
  font-weight: 800;
  color: #5A6868;
  padding-bottom: 2px;
}

.box-cup-info .dates {
  font-size: medium;
  font-weight: 500;
  color: #5A6868;
  padding-bottom: 12px;
}

.box-cup-info .description {
  font-size: medium;
  font-weight: 300;
  color: #000;
  padding-bottom: 12px;
}
