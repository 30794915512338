.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
}

.leaflet-container {
  height: 100vh;
  width: 100vw;
}

.currently-inactive {
  filter: grayscale(100%);
  opacity: 0.35 !important;
}

.leaflet-control { z-index: 0 !important}
.leaflet-pane { z-index: 0 !important}
.leaflet-top, .leaflet-bottom {z-index: 0 !important}