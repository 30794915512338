.socials {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: center;
}

.social-link {
  width: 50px;
  height: 50px;
  padding: 8px;
}